@font-face {
	font-family: "brutal-b";
	src: url("https://static-assets-prod.epicgames.com/epic-store/static/webpack/5f601a4caa6f187bd35621b49fc8e2bc.woff2")
		format('woff');
}
@font-face {
	font-family: "brutal";
	src: url("https://static-assets-prod.epicgames.com/epic-store/static/webpack/4555758a9a1a19e87a66eceaf00b1b23.woff2")
		format('woff');
}

body {
	background: #121212;
	font-family: 'brutal';
	font-size: 30px;
}

* {
	outline: none;
	box-sizing: border-box;
}

input[type='search'] {
	background-image: url('/search.svg');
	background-position: 5px 14px;
	background-repeat: no-repeat;
	padding-left: 30px;
	&::-webkit-search-cancel-button {
		position: relative;
		right: 20px;

		-webkit-appearance: none;
		height: 20px;
		width: 20px;
		border-radius: 10px;
		background: #2a2a2a;
	}
}

.font-b {
	font-family: "brutal-b";
	// font-weight: bold;
}

.font-s {
	font-family: "brutal";
	font-size: 12px;
}

.white {
	color: #fff !important;
}
.nav-container {
	// display: flex;
	flex-direction: row;
	width: 100%;
	height: 53px;
	background: #2a2a2a;
	z-index: 10;
	position: fixed;
	top: 0;
}

.tab {
	// display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	// color: #b8b8b8;
	// letter-spacing: 0.1rem;
	transition: all 0.1s ease;
	// font-size: 0.8rem;
	&:hover {
		color: white;
		background: #0078f2;
		transition: all 0.5s ease;
	}
}

// @media (min-width: 800px) {
// 	.tab {
// 		font-size: 0.8rem;
// 	}
// }
// .image-logo{
// 	max-width: 100%;
//     // height: auto;
// 	width:auto\9;
// }

.image-gp {
	object-fit: contain;
	text-align: center;
}
.iconSoc:hover {
	-moz-transform: translate(0px, -2px);
	-ms-transform: translate(0px, -2px);
	-o-transform: translate(0px, -2px);
	-webkit-transform: translate(0px, -2px);
	transform: translate(0px, -2px);
}

.word-wrap{
	word-wrap: break-word;
}