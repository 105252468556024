.embla {
	position: relative;
}

.embla__viewport {
	overflow: hidden;
	max-height: 700px;
}

/* .embla__viewport.is-draggable {
	cursor: move;
	cursor: grab;
}

.embla__viewport.is-dragging {
	cursor: grabbing;
} */

.embla__container {
	display: flex;
	will-change: transform;
	margin-left: -1rem;
}

.embla__slide {
	flex: 0 0 auto;
	/* Chame Here To Change The Width On The Current Picture */
	width: 70%;              
	position: relative;
	padding-left: 1rem;
	counter-increment: embla;
	opacity: 0.3;
	
}
.embla__slide:hover{
	opacity: 1;
}

.embla__slide:hover + .embla__button{
	opacity: 1;
}



.embla__slide.is-selected{
	opacity: 1;
}

.embla__button {
	/* opacity: 0; */
	background-color: black;
	/* visibility: hidden;; */
	position: absolute;
	z-index: 1;
	top: 50%;
	transform: translateY(-50%);
	border: 0;
	/* width: 5rem;
	height: 5rem; */
	justify-content: center;
	align-items: center;
	padding: 0;
}

.embla__button:hover{
background: rgb(226, 43, 43);
}

.embla__button:not(:disabled) {
	/* width: 20%;
	height: 15%; */
	cursor: pointer;
	fill: #ffffff;
}

.embla__button:disabled {
	display: none;
	fill: #e9e9e9;
}

.embla__button__svg {
	/* padding: 0% 20%; */
	height: 3.5rem;
	width: 3.5rem;
}

.embla__button--prev {
	left: 11%;
}

.embla__button--next {
	right: 11%;
}

.embla__dots {
	position: absolute;
	margin-top: 1rem;
	display: flex;
	list-style: none;
	padding-left: 0;
	justify-content: center;
	left: 0;
	right: 0;
	top: 100%;
}

.embla__dot {
	background-color: transparent;
	cursor: pointer;
	position: relative;
	padding: 0;
	width: 0.4rem;
	height: 0.4rem;
	border-radius: 50%;
	margin-right: 0.75rem;
	margin-left: 0.75rem;
	display: flex;
	align-items: center;
}

.embla__dot:after {
	background-color: #b6b6b6;
	width: 100%;
	height: 0.4rem;
	content: "";
	border-radius: 50%;

}

.embla__dot.is-selected:after {
	background-color: #020202;
	opacity: 1;
	max-width: 73vw;
}

.content {
	background-color: rgba(0, 0, 0, 0);
	/* padding-top: 5rem; */
	padding-bottom: 1rem;
	margin-top: 3rem;
	margin-bottom: 2rem;
	margin-right: auto;
	margin-left: auto;
	max-width: 200rem;	
	/* width: 100%; */

}

/* @media(max-width:768px){
	.embla__slide {
		width: 85%;
	}
	
} */

@media(max-width:1024px){
	.embla__slide {
		width: 90%;
		opacity: 1;
	}
	.embla__button{
		display: none;
	}
}